
import {computed, defineComponent, onMounted, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import RecentCards from "@/components/base/common/RecentCards.vue";
import InvoiceCard from "@/views/invoice/InvoiceCard.vue";

export default defineComponent({
  name: "MyInvoices",
  components: {InvoiceCard, RecentCards, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('My Invoices',
        [
          {link: false, router: '', text: 'My Invoices'}
        ]
      )
    })
    const filterObject = ref({myInvoice: true})
    const page = computed(() => store.state.InvoiceModule.listInvoicePage)
    return {
      page,
      ...LoadFilterObjects(Actions.LOAD_LIST_INVOICES, filterObject.value, ['client', 'policy', 'claim', 'insured']),
      ...LoadPanel(),
    }
  }
})
